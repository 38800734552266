<template>
  <div class="pt-10 pb-20 bg-white px-4 lg:px-20 2xl:px-32">
    <div>
      <h1 class="title font-poppins-medium text-3xl lg:text-4xl mx-8 text-black text-center text-theme-1">How to get great things done</h1>
      <h4 class="title text-black mx-8 mt-4 font-poppins-medium text-2xl lg:text-4xl text-center text-theme-1"> Our method</h4>
    </div>
    <div class="lg:flex w-2/2 lg:items-center mt-10">
      <div class="mt-4 flex justify-center lg:w-1/2 lg:h-72 lg:block">
        <img class="lg:hidden" src="../assets/images/Objeto-mobile.png" alt="Objeto">
        <img class="lg:block hidden h-60 xl:h-72 2xl:h-80 w-full object-contain" src="../assets/images/Objeto-inteligente-vectorial.png" alt="">
      </div>
      <div class="lg:w-1/2">
        <div class="box flex w-3/3 lg:grid lg:grid-cols-8 lg:gap-x-4 px-8 py-4 mt-20 lg:mt-4 lg:-ml-10">
          <div class="w-1/3 lg:w-full lg:col-start-1 lg:col-span-2 2xl:col-span-1">
            <img class="lg:hidden" src="../assets/images/research-mobile.png" alt="research">
            <img class="hidden lg:block h-28 w-28 object-contain" src="../assets/images/research.png" alt="research">
          </div>
          <div class="w-2/3 lg:w-full lg:col-start-3 lg:col-span-6 2xl:col-start-2">
            <h2 class="font-poppins-medium text-base text-theme-1">Research + Discover</h2>
            <p class="font-poppins-regular text-xs mt-3 text-theme-1">We analyse all stages of the product or service lifecycle, considering how we can improve the lives of consumers through automation and AI. Our solution architects focus on your unique challenges & discover a roadmap tailored to your business needs.</p>
          </div>
        </div>
        <div class="box flex w-3/3 lg:grid lg:grid-cols-8 lg:gap-x-4 px-8 py-4 mt-10 lg:mt-5">
          <div class="w-1/3 lg:w-full lg:col-start-1 lg:col-span-2 2xl:col-span-1">
            <img class="lg:hidden" src="../assets/images/design-mobile.png" alt="research">
            <img class="hidden lg:block h-28 w-28 object-contain" src="../assets/images/design.png" alt="research">
          </div>
          <div class="w-2/3 lg:w-full lg:col-start-3 lg:col-span-6 2xl:col-start-2">
            <h2 class="font-poppins-medium text-base text-theme-1">Design + Develop</h2>
            <p class="font-poppins-regular text-xs mt-3 text-theme-1">Purpose without action is meaningless. We implement
              the decisions made in the strategy into the operational
              part of the company. An agile team of deep tech engineers will turn your vision into a perfect fit solution.</p>
          </div>
        </div>
        <div class="box flex w-3/3 lg:grid lg:grid-cols-8 lg:gap-x-4 px-8 py-4 mt-10 lg:mt-5 lg:-ml-10">
          <div class="w-1/3 lg:w-full lg:col-start-1 lg:col-span-2 2xl:col-span-1">
            <img class="lg:hidden" src="../assets/images/test-mobile.png" alt="research">
            <img class="hidden lg:block h-28 w-28 object-contain" src="../assets/images/test.png" alt="research">
          </div>
          <div class="w-2/3 lg:w-full lg:col-start-3 lg:col-span-6 2xl:col-start-2">
            <h2 class="font-poppins-medium text-base text-theme-1">Test + Scale</h2>
            <p class="font-poppins-regular text-xs mt-3 text-theme-1">Supported by our team, we test & validate our tech, laying the foundation for more efficient & resilient business.
              We guide you through the process of digital acceleration
              & build momentum to scale.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HowWeWork"
}
</script>
<style lang="scss" scoped>
.box {
  box-shadow: -19px 5px 25.759999999999998px 2.24px rgba(51, 51, 51, 0.2);
  border-radius: 32px;
}
@media only screen and (min-width: 1024px) {
  .title {
    font-weight: 600;
  }
}
</style>
