<template>
  <div>
    <div>
      <h1 class="w-full text-center text-4xl font-poppins-bold mt-32 lg:mt-20">Case Studies</h1>
    </div>
    <div class="mt-10 grid grid-cols-6 gap-y-10 lg:gap-y-5 gap-x-5 lg:mx-20 mx-10 relative z-10 mb-20">
      <div class="article relative md:col-span-3 col-span-6 xl:col-span-2" v-for="(clientsData, index) in clientsDatas" :key="index">
        <div class="image-content flex lg:col-span-3 relative">
          <a :href="clientsData.name">
            <img :src="clientsData.img" id="clients-img" class="clients-img" alt="">
            <a :href="clientsData.name" class="hoverImg-content">
              <img class="second-img" :src="clientsData.hoverImg" alt="">
            </a>
          </a>
        </div>
        <div class="text-content">
          <div class="text-inner">
            <h2 class="clients-name font-poppins-medium text-lg hover:hidden text-lg md:text-xl">
              <a :href="clientsData.name">{{ clientsData.txt }}</a>
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "Clients",
  data() {
    return {
      hovered: false,
      clientsDatas: [
        {img: require("../assets/caseStudies/hilo-group.png"), txt: "AI Digital Human", hoverImg: require("../assets/caseStudies/navantia.png"), name: "navantia"},
        {img: require("../assets/caseStudies/peachland.png"), txt: "Martech x Influencers", hoverImg: require("../assets/caseStudies/peachland1.png"), name: "/peachland"},
        {img: require("../assets/caseStudies/axa.png"), txt: "Insuretech", hoverImg: require("../assets/caseStudies/axa1.png"), name: "axa"},
        {img: require("../assets/caseStudies/rental-car.png"), txt: "Rental App", hoverImg: require("../assets/caseStudies/rental-car1.png"), name: "santander"},
        {img: require("../assets/caseStudies/quddy.png"), txt: "Retail AI", hoverImg: require("../assets/caseStudies/quddy1.png"), name: "quddy"},
        {img: require("../assets/caseStudies/santander.png"), txt: "Fintech + Insuretech", hoverImg: require("../assets/caseStudies/santander1.png"), },
        {img: require("../assets/caseStudies/massmedia.png"), txt: "AI Martech", hoverImg: require("../assets/caseStudies/massmedia1.png"), name: "mass-media"},
        {img: require("../assets/caseStudies/visa-cloud.png"), txt: "Traveltech", hoverImg: require("../assets/caseStudies/visa-cloud1.png"), name: "/visa-cloud" },
        {img: require("../assets/caseStudies/pepe-housing.png"), txt: "Proptech", hoverImg: require("../assets/caseStudies/pepe-housing1.png"), name: "/pepe-housing" },
      ]
    }
  },
}
</script>
<style scoped lang="scss">
.article:hover {
  & .text-inner {
    opacity: 1;
    clip-path: inset(0 0 0 0);
    -webkit-clip-path: inset(0 0 0 0);
    -webkit-transition: 1.5s cubic-bezier(.24,.12,0,1.07);
    -o-transition: 1.5s cubic-bezier(.24,.12,0,1.07);
    transition: 1.5s cubic-bezier(.24,.12,0,1.07);
  }
  & .image-content {
    clip-path: inset(30px 30px 30px 30px);
    -webkit-clip-path: inset(30px 30px 30px 30px);
  }
  & .clients-img {
    opacity: 0;
  }
}
.clients-name {
 // transform: rotate(90deg);
  z-index: 2;
  font-family: Poppins,sans-serif;
  font-weight: 700;
  color: #1f1f1f;
  //font-size: 24px;
  line-height: 1.5em;
  letter-spacing: -.02em;
  margin: 25px 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  & a {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    text-transform: inherit;
  }
}
//.clients-img:hover {
//  opacity: 0;
//}
.hoverImg-content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  max-width: 100%;
}
.second-img {
  height: auto;
  max-width: 100%;
  border: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.image-content {
  clip-path: inset(0 0 0 0);
  -webkit-clip-path: inset(0 0 0 0);
  -webkit-transition: 1.4s cubic-bezier(.13,.43,0,.94);
  -o-transition: 1.4s cubic-bezier(.13,.43,0,.94);
  transition: 1.4s cubic-bezier(.13,.43,0,.94);
}
//.image-content:hover {
//
//}
.clients-img {
  z-index: 2;
}
.text-content {
  position: absolute;
  bottom: 47px;
  left: -32px;
}
.text-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  -webkit-transform: rotate(-90deg) translate(-11%,102%);
  -ms-transform: rotate(-90deg) translate(-11%,102%);
  transform: rotate(-90deg) translate(-11%,102%);
  -webkit-transform-origin: left bottom;
  -ms-transform-origin: left bottom;
  transform-origin: left bottom;
  opacity: 0;
  -webkit-transition: .3s cubic-bezier(0,.96,.25,1);
  -o-transition: .3s cubic-bezier(0,.96,.25,1);
  transition: .3s cubic-bezier(0,.96,.25,1);
  clip-path: inset(0 100% 0 0);
  -webkit-clip-path: inset(0 100% 0 0);
}
</style>
