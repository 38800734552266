<template>
  <div class="bg-theme-1">
    <Navbar id="navbar"/>
    <div id="app" class="bg-theme-1" v-scroll-spy>
      <About id="one"/>
      <Services id="two"/>
      <HowWeWork id="three" />
      <SoftwareFactory id="four"/>
      <Slider id="five" />
      <OurExperience id="six"/>
      <TrustedUs id="seven"/>
      <Footer id="eight"/>
    </div>
  </div>
</template>

<script>


import Navbar from "@/components/Navbar";
import About from "@/components/About";
import Services from "@/components/Services";
import HowWeWork from "@/components/HowWeWork";
import Footer from "@/components/Footer";
import SoftwareFactory from "@/components/SoftwareFactory";
import Slider from "@/components/Slider";
import OurExperience from "@/components/OurExperience";
import TrustedUs from "@/components/TrustedUs";

export default {
  name: 'Home',
  components: {
    TrustedUs,
    OurExperience,
    Slider,
    SoftwareFactory,
    Footer,
    HowWeWork,
    Services,
    About,
    Navbar

  }
}
</script>

<style>

</style>
