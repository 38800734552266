import Vue from 'vue'
import VueRouter from  'vue-router'
import Home from "@/components/Home";
import CaseStudies from "@/components/CaseStudies";
import Quddy from "@/components/Quddy";
import Peachland from "@/components/Peachland";
import VisaCloud from "@/components/VisaCloud";
import PepeHousing from "@/components/PepeHousing";
import Axa from "@/components/Axa";
import Santander from "@/components/Santander";
import Navantia from "@/components/Navantia";
import Massmedia from "@/components/Massmedia";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: Home
    },
    {
        path: '/case-studies',
        name: 'case-studies',
        component: CaseStudies,
        // children: [
        //     {
        //         path: 'quddy',
        //         component: Quddy
        //     }
        // ]
    },
    {
        path: '/quddy',
        name: 'quddy',
        component: Quddy
    },
    {
        path: '/peachland',
        name: 'peachland',
        component: Peachland
    },
    {
        path: '/visa-cloud',
        name: 'visaCloud',
        component: VisaCloud
    },
    {
        path: "/pepe-housing",
        name: "pepeHousing",
        component: PepeHousing
    },
    {
        path: "/axa",
        name: "axa",
        component: Axa
    },
    {
        path: "/santander",
        name: "santander",
        component: Santander
    },
    {
        path: "/navantia",
        name: "navantia",
        component: Navantia
    },
    {
        path: "/mass-media",
        name: "massMedia",
        component: Massmedia
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
