<template>
  <div class="pb-20 pt-20 bg-white lg:pt-28 lg:pb-28">
    <swiper ref="swiper" :options="swiperOption"
            :slidesPerView="1"
            :navigation="true"
            releaseOnEdges
            :mousewheel="true"
            :speed="1000"
            :fadeEffect="{crossFade: true}"
            :modules="modules"
            class="mySwiper p-10">
      <SwiperSlide id="one" class="py-8 lg:flex lg:items-center lg:gap-x-10 lg:px-32 xl:px-72">
        <div class="flex justify-center">
          <img src="../assets/slider/slider-1-mobile.png" alt="">
        </div>
        <div class="box flex w-3/3 px-8 py-4 mt-20 lg:mt-0 lg:h-fit mx-4 lg:w-full">
          <div>
            <h2 class="font-poppins-medium text-base text-theme-1">Strategy & Consulting</h2>
            <p class="font-poppins-regular text-xs mt-3 text-theme-1">We will review and analyse your existing systems and processes to further develop
              a bespoke workflow transformation strategy adapted to your business needs to boost productivity and efficiency across your organization.</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide id="two"  class="py-8 lg:flex lg:items-center lg:gap-x-10 lg:px-32 xl:px-72">
        <div class="flex justify-center">
          <img src="../assets/slider/second-mobile.png" alt="">
        </div>
        <div class="box flex w-3/3 px-8 py-4 mt-20 lg:mt-0 lg:h-fit mx-4 lg:w-full">
          <div>
            <h2 class="font-poppins-medium text-base text-theme-1">Workflow Software Development</h2>
            <p class="font-poppins-regular text-xs mt-3 text-theme-1">We build workflow software aimed to improve productivity and efficiency across your organization. Our experienced software development team works closely with you to provide solutions tailored to your business needs.</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide id="three"  class="py-8 lg:flex lg:items-center lg:gap-x-10 lg:px-32 xl:px-72">
        <div class="flex justify-center">
          <img src="../assets/slider/third-mobile.png" alt="">
        </div>
        <div class="box flex w-3/3 px-8 py-4 mt-20 lg:mt-0 lg:h-fit mx-4 lg:w-full">
          <div>
            <h2 class="font-poppins-medium text-base text-theme-1">Automation</h2>
            <p class="font-poppins-regular text-xs mt-3 text-theme-1">Business Process Automation is a straightforward, effective way, to grow your company.
              Working with your leadership team, we provide an expert technology advisor role to understand your business-specific goals and develop an end-to-end transformation strategy.</p>
          </div>
        </div>
      </SwiperSlide>
    </swiper>
  </div>
</template>
<script>
import { Mousewheel, Pagination, Navigation } from 'swiper'

import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
SwiperCore.use([Mousewheel, Pagination, Navigation ])
export default {
  name: "Slider",
  components: {Swiper, SwiperSlide},
  data() {
    return {
      modules: [Mousewheel, Pagination],
      swiperOption: {
        spaceBetween: 40,
        touchReleaseOnEdges: true,
        mousewheel: {
          releaseOnEdges: true
        },
        // autoHeight: true,
        setWrapperSize: true,
        // pagination: {
        //   el: '.swiper-pagination',
        //   type: 'bullets',
        //   clickable: true,
        // },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.box {
  box-shadow: -19px 5px 25.759999999999998px 2.24px rgba(51, 51, 51, 0.2);
  border-radius: 32px;
}
.mySwiper {
  ::v-deep.swiper-pagination {
    & .swiper-pagination-bullet-active {
      background: transparent !important;
      border: 1px white solid;
      border-radius: 100%;
    }
  }
  ::v-deep .swiper-button-next::after {
    font-size: 20px !important;
    color: grey !important;
    font-weight: 900 !important;
  }
  ::v-deep .swiper-button-prev::after {
    font-size: 20px !important;
    color: grey !important;
    font-weight: 900 !important;
  }
}
@media only screen and (min-width: 1024px) {
  .box {
    height: min-content !important;
    align-self: center !important;
  }
  .mySwiper {
    ::v-deep .swiper-button-next {
      right: 100px !important;
    }
    ::v-deep .swiper-button-prev {
      left: 100px !important;
    }
  }
}
</style>
