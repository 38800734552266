import Vue from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import Scrollspy from 'vue2-scrollspy';
import 'swiper/swiper-bundle.css'
import "./vee-validate"
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import store from "./store/store"
import VueParticles from 'vue-particles'
import lottie from 'vue-lottie';
import router from "@/router";

Vue.use(VueParticles)
Vue.use(lottie)

import "swiper/swiper.scss";
// use default options
Vue.use(Scrollspy);
Vue.use(Toast, {
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(
        t => t.type === toast.type
    ).length !== 0) {
      // Returning false discards the toast
      return false;
    }
    // You can modify the toast if you want
    return toast;
  }
})
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
