<template>
  <div class="pb-20 bg-white pt-20 lg:py-28">
    <h1 class="font-poppins-medium text-3xl lg:text-4xl mx-8 text-theme-1 text-center lg:font-semibold">They trusted us,<br class="lg:hidden"> We delivered.</h1>
    <div class="w-full lg:flex lg:px-10 xl:px-32 lg:gap-x-2 xl:gap-x-5">
      <img class="mt-14 flex justify-center mx-auto lg:object-contain" src="../assets/logos/1.logo-pepe-1linia-transp-ciemne-1.png" alt="">
      <img class="mt-14 flex justify-center mx-auto lg:object-contain" src="../assets/logos/emaarr-768x376.png" alt="">
      <img class="mt-14 flex justify-center mx-auto lg:object-contain" src="../assets/logos/Heineken-Logo(3).png" alt="">
      <img class="mt-14 flex justify-center mx-auto lg:object-contain" src="../assets/logos/logo.png" alt="">
      <img class="mt-14 flex justify-center mx-auto lg:object-contain" src="../assets/logos/nhs-logo-1.png" alt="">
      <img class="mt-14 flex justify-center mx-auto lg:object-contain" src="../assets/logos/Objetointeligentevectorial.png" alt="">
    </div>
  </div>
</template>
<script>
export default {
  name: "TrustedUs"
}
</script>
