<template>
  <div>
    <ClientsSlider>
      <template #title>
        Navantia
      </template>
      <template #slide>
        <SwiperSlide id="one">
          <img class="mx-auto" src="../assets/navantia/img.png" alt="">
        </SwiperSlide>
        <SwiperSlide id="two">
          <img class="mx-auto" src="../assets/navantia/img_1.png" alt="">
        </SwiperSlide>
        <SwiperSlide id="three">
          <img class="mx-auto" src="../assets/navantia/img_2.png" alt="">
        </SwiperSlide>
        <SwiperSlide id="four">
          <img class="mx-auto" src="../assets/navantia/img_3.png" alt="">
        </SwiperSlide>
        <SwiperSlide id="five">
          <img class="mx-auto" src="../assets/navantia/img_4.png" alt="">
        </SwiperSlide>
      </template>
    </ClientsSlider>
  </div>
</template>
<script>
import ClientsSlider from "@/layouts/ClientsSlider";
import {SwiperSlide} from "swiper-vue2"
export default {
  name: "Navantia",
  components: {ClientsSlider, SwiperSlide}
}
</script>
<style lang="scss" scoped>

</style>
