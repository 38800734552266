<template>
  <div class="bg-white pt-20 pb-20 lg:px-20 2xl:px-32">
    <div>
      <h1 class="font-poppins-medium text-3xl lg:text-4xl mx-8 text-black text-center text-theme-1 lg:font-semibold">Smart solutions that fit your business</h1>
      <h4 class="text-black mx-8 mt-2 font-poppins-regular text-sm lg:text-xl text-center text-theme-1">We can help boost your business
        productivity <br class="lg:block hidden"> through automations & AI</h4>
    </div>
    <div class="mt-14 grid grid-cols-1 mx-8 lg:mx-0 lg:grid-cols-8">
      <div class="col-span-1 row-span-1 row-start-1 place-self-center lg:col-start-1 lg:col-span-2 lg:row-start-1 object-contain">
        <img class="lg:hidden" src="../assets/images/project-mobile.png" alt="project">
        <img class="hidden lg:block h-40 w-full" src="../assets/images/project.png" alt="project">
        <div class="w-full mx-auto text-center mt-2 lg:mt-5">
          <span class="font-poppins-medium mx-auto text-theme-1 lg:font-semibold">Project scope</span>
        </div>
      </div>
      <div class="col-span-1 row-span-1 row-start-2 place-self-center mt-6 lg:mt-0 lg:col-start-3 lg:col-span-2 lg:row-start-1 object-contain">
        <img class="lg:hidden" src="../assets/images/uxui-mobile.png" alt="project">
        <img class="hidden lg:block h-40 w-full" src="../assets/images/ux-ui.png" alt="project">
        <div class="w-full mx-auto text-center mt-2 lg:mt-5">
          <span class="font-poppins-medium mx-auto text-theme-1 lg:font-semibold">UX/UI</span>
        </div>
      </div>
      <div class="col-span-1 row-span-1 row-start-3 place-self-center mt-6 lg:mt-0 lg:col-start-5 lg:col-span-2 lg:row-start-1 object-contain">
        <img class="lg:hidden" src="../assets/images/mvp-mobile.png" alt="project">
        <img class="lg:block hidden h-40 w-full" src="../assets/images/mvp.png" alt="project">
        <div class="w-full mx-auto text-center mt-2 lg:mt-5">
          <span class="font-poppins-medium mx-auto text-theme-1 lg:font-semibold">MVP Builder</span>
        </div>
      </div>
      <div class="col-span-1 row-span-1 row-start-4 place-self-center mt-6 lg:mt-0 lg:col-start-7 lg:col-span-2 lg:row-start-1 object-contain">
        <img class="lg:hidden" src="../assets/images/dedicatedteam-mobile.png" alt="project">
        <img class="lg:block hidden h-40 w-full" src="../assets/images/dedicated-team.png" alt="project">
        <div class="w-full mx-auto text-center mt-2 lg:mt-5">
          <span class="font-poppins-medium mx-auto text-theme-1 lg:font-semibold">Dedicated Team</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Services"
}
</script>
<style lang="scss" scoped>

</style>
