<template>
  <div>
    <white-navbar />
    <div>
      <div class="mt-20 lg:mt-32">
        <h1 class="w-full text-center text-2xl md:text-4xl font-poppins-bold mt-32 lg:mt-20"><slot name="title"></slot></h1>
      </div>
      <div class="mt-20 mb-32">
        <swiper ref="swiper" :options="swiperOption"
                :autoplay="{
                  delay: 2000,
                  disableOnInteraction: false,
              }"
                :centeredSlides="true"
                :spaceBetween="40"
                :slidesPerView="1"
                :navigation="true"
                :speed="1000"
                :fadeEffect="{crossFade: true}"
                :modules="modules"
                class="clientsSwiper p-10">
          <slot name="slide"></slot>
        </swiper>
      </div>
      <Footer />
    </div>
  </div>
</template>
<script>
import { Autoplay, Mousewheel, Pagination, Navigation } from 'swiper'

import { SwiperCore, Swiper } from 'swiper-vue2'
SwiperCore.use([Mousewheel, Pagination, Navigation, Autoplay ])
import WhiteNavbar from "@/components/WhiteNavbar";
import Footer from "@/components/Footer";
export default {
  name: "ClientsSlider",
  data() {
    return {
      modules: [Mousewheel, Pagination],
      swiperOption: {
        spaceBetween: 40,
        touchReleaseOnEdges: true,
        centeredSlides: true,
        mousewheel: {
          releaseOnEdges: true
        },
        // autoHeight: true,
        setWrapperSize: true,
        // pagination: {
        //   el: '.swiper-pagination',
        //   type: 'bullets',
        //   clickable: true,
        // },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      }
    }
  },
  components: {Footer, WhiteNavbar, Swiper}
}
</script>
<style lang="scss" scoped>
@media only screen and (min-width: 768px) {
  .clientsSwiper {
    margin: auto;
    width: 70% !important;
    & img {
      object-fit: contain;
    }
    & .swiper-wrapper {
      margin: auto;
      width: 70% !important;
    }
  }
}
</style>
