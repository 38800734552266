<template>
  <div class="pt-10 pb-20 bg-white px-4 bg-theme-1 lg:pt-20 lg:px-20 2xl:px-32">
    <div class="flex justify-center mt-10 lg:hidden">
      <img src="../assets/images/Objeto-vectorial-mobile.png" alt="">
    </div>
    <div class="lg:flex lg:2/2">
      <div class="lg:w-1/2 lg:flex lg:flex-col	lg:justify-center">
        <div class="mt-8">
          <h1 class="font-poppins-medium text-3xl mx-8 text-black text-center lg:text-left lg:text-4xl text-white lg:mx-0 font-semibold">We are a software factory</h1>
        </div>
        <div class="mt-4">
          <p class="text-white text-center lg:text-left w-full font-poppins-regular text-xs lg:text-base">Our goal is to improve business results, increasing revenues, reducing costs and improving customer satisfaction, to enhance companies’ profitability leveraging disruptive technologies & innovative solutions. </p>
        </div>
        <div class="flex justify-center mt-6 lg:block">
          <button @click="showModal" class="flex contact text-white bg-transparent border-2 border-white h-12 p-6 lg:px-14 text-lg font-poppins-regular items-center hover:bg-white hover:text-theme-6">
            Case Studies
          </button>
        </div>
      </div>
      <div class="lg:flex lg:items-center lg:gap-x-10 justify-center hidden lg:w-1/2">
        <img class="lg:h-60 2xl:h-72" src="../assets/images/Objeto-inteligente-vectorial-desk.png" alt="">
      </div>
    </div>
    <transition name="fade">
      <div v-if="showBackDrop" class="backdrop hidden relative" :class="showBackDrop ? 'showBackdrop' : ''">
        <div @click="showBackDrop = false" class="absolute right-5 top-5 cursor-pointer">
          <svg height="40px" id="Layer_1" style="fill: white" version="1.1" viewBox="0 0 512 512" width="40px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"/></svg>
        </div>
        <div class="flex justify-center h-full items-center">
            <ul class="font-poppins-medium text-white text-lg text-center lg:text-2xl">
              <li class="hover-underline-animation"><a href="#" class="list__nav-link">marketing tech</a></li>
              <li class="hover-underline-animation mt-4"><a href="#" class="list__nav-link">health tech</a></li>
              <li class="hover-underline-animation mt-4"><a href="#" class="list__nav-link">automation</a></li>
              <li class="hover-underline-animation mt-4"><a href="#" class="list__nav-link">e-commerce</a></li>
              <li class="hover-underline-animation mt-4"><a href="#" class="list__nav-link">prop-tech</a></li>
            </ul>
        </div>
      </div>
    </transition>
    <!--    <ContactModal ref="dialog" />-->
  </div>
</template>
<script>
// import ContactModal from "@/components/ContactModal";
export default {
  name: "SoftwareFactory",
  // components: {ContactModal},
  data() {
    return {
      showBackDrop: false
    }
  },
  methods: {
    showModal() {
      window.scrollTo(0, 0);
      return this.$router.push("/case-studies")
    }
  }
}
</script>
<style lang="scss" scoped>
.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100%;
}

.showBackdrop {
  display: block !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.hover-underline-animation {
  //display: inline-block;
  position: relative;
  color: white;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
</style>
