<template>
  <div class="h-full">
    <WhiteNavbar />
    <div class="mt-20">
      <Clients />
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import WhiteNavbar from "@/components/WhiteNavbar";
import Clients from "@/components/Clients";
export default {
  name: "CaseStudies",
  components: { Clients, WhiteNavbar, Footer},
  data() {
    return {
      hovered: false,
      burgerVisibility: false,
      direction: "right",
      effect: "slide",
      duration: 800,
      desktopVisibility: false,
      view: {
        underNavigation: true
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.card {
  position: absolute;
  transform: translate(0%, -50%);
  width: 350px;


  &__content {
    text-align: center;
    position: relative;
    padding: 5em;
    transition: transform 3s;
    background-color: #264653;
    transform-style: preserve-3d;
  }

  &:hover .card__content {
    transform: rotateY(.5turn);
  }

  &__back, &__front {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2em;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    display: grid;
    align-content: center;
    height: 300px;
  }

  &__front {
    background-color: lightgreen;
    color: #333;
  }

  &__title {
    font-size: 3rem;
    transform: translateZ(200px);
  }

  &__subtitle {
    transform: translateZ(5rem);
  }

  &__body {
    transform: translateZ(5rem);
  }

  &__back {
    transform: rotateY(.5turn);
    background: lightblue;
    color: #333;
  }
}
</style>
