<template>
  <div class="experience relative pb-20 bg-white">
    <vue-particles
        class="particle"
        color="#000000"
        :particleOpacity="0.7"
        linesColor="#000000"
        :particlesNumber="80"
        shapeType="circle"
        :particleSize="5"
        :linesWidth="2"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="3"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
    >
    </vue-particles>
    <div class="relative">
      <div>
        <h1 class="font-poppins-medium text-3xl lg:text-4xl mx-8 text-theme-1 text-center lg:font-semibold">Our experience</h1>
      </div>
      <div class="mt-4">
        <p class="text-center font-poppins-regular text-base mx-8 lg:text-xl text-theme-1">Where we have worked in the past
          and delivered <br class="hidden lg:block"> value to scaling and global companies. </p>
      </div>
    </div>
    <div class="relative px-8 mt-4 lg:flex lg:justify-center lg:mt-20 2xl:mt-20 2xl:mb-20">
      <div class="flex justify-center gap-x-3 lg:gap-x-8">
        <div class="box flex place-self-center drop-shadow-2xl">
            <h2 class="font-poppins-medium flex w-full place-self-center justify-center text-xs lg:text-lg lg:font-medium uppercase whitespace-nowrap text-theme-1">health</h2>
        </div>
        <div class="box flex place-self-center drop-shadow-2xl">
          <h2 class="font-poppins-medium flex w-full place-self-center justify-center text-xs lg:text-lg lg:font-medium uppercase whitespace-nowrap text-theme-1">REAL ESTATE</h2>
        </div>
        <div class="box flex place-self-center drop-shadow-2xl">
          <h2 class="font-poppins-medium flex w-full place-self-center justify-center text-xs lg:text-lg lg:font-medium uppercase whitespace-nowrap text-theme-1">marketing</h2>
        </div>
      </div>
      <div class="flex justify-center gap-x-3 lg:gap-x-8 mt-4 lg:mt-0">
        <div class="box flex place-self-center drop-shadow-2xl lg:ml-8">
          <h2 class="font-poppins-medium flex w-full place-self-center justify-center text-xs lg:text-lg lg:font-medium uppercase whitespace-nowrap text-theme-1">tourism</h2>
        </div>
        <div class="box flex place-self-center drop-shadow-2xl">
          <h2 class="font-poppins-medium flex w-full place-self-center justify-center text-xs lg:text-lg lg:font-medium uppercase whitespace-nowrap text-theme-1">fintech</h2>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OurExperience"
}
</script>
<style lang="scss" scoped>
//.experience {
//  background-image: url("../assets/slider/Group1.png");
//  background-size: cover;
//  background-color: white;
//  /*height: 100vh;*/
//  background-repeat: no-repeat;
//}
.box {
  box-shadow: -19px 5px 25.759999999999998px 2.24px rgba(51, 51, 51, 0.2);
  border-radius: 20px;
  background-color: white;
  width: 90px;
  height: 90px;
}
@media only screen and (min-width: 1024px) {
  //.experience {
  //  background-image: url("../assets/images/Group2.png");
  //}
  .box {
    width: 150px;
    height: 150px;
  }
}
.particle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
